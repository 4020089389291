import React from "react"

import { Alert, Stack, Text } from "@kiwicom/orbit-components"
import InformationCircle from "@kiwicom/orbit-components/lib/icons/InformationCircle"

import {
  formatAsAbbreviatedDate,
  formatAsTime,
  getDefaultTimezone,
} from "utils/date-utils"

import { TimeBlock } from "../utils"

function formatTimeBlock(timeBlock: TimeBlock) {
  const timezone = getDefaultTimezone()
  const startDay = formatAsAbbreviatedDate(timeBlock.startTime, timezone)
  const startTime = formatAsTime(timeBlock.startTime, timezone)
  const endDay = formatAsAbbreviatedDate(timeBlock.endTime, timezone)
  const endTime = formatAsTime(timeBlock.endTime, timezone)

  if (startDay !== endDay) {
    return `${startTime} on ${startDay} - ${endTime} on ${endDay}`
  }
  return `${startTime} - ${endTime} on ${startDay}`
}

export const PatternPreview = ({
  timeBlocks,
  hasNoEndTime,
}: {
  timeBlocks: TimeBlock[]
  hasNoEndTime: boolean
}) => {
  let description =
    timeBlocks.length > 1
      ? "Stops affected within the following time periods"
      : "Stops affected within the following time period"

  if (timeBlocks.length === 0) {
    description = "No stops will be affected"
  }

  const blocksToDisplay = hasNoEndTime ? timeBlocks.slice(0, 7) : timeBlocks
  return (
    <Alert type="info" title={description} icon={<InformationCircle />}>
      {blocksToDisplay.length > 0 && (
        <Stack spacing="XSmall">
          <ul>
            {blocksToDisplay.map((timeBlock, index) => {
              return (
                <li key={"calculated-time-period-" + index}>
                  <Text>{formatTimeBlock(timeBlock)}</Text>
                </li>
              )
            })}
            {hasNoEndTime && (
              <li key="calculated-time-period-end">
                <Text>...</Text>
              </li>
            )}
          </ul>
          {hasNoEndTime && (
            <Text>
              The stop replacement will be applied indefinitely without an end
              time.
            </Text>
          )}
        </Stack>
      )}
    </Alert>
  )
}
